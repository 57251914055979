import SvgPhone from "@clayton-homes/ui-icons/24/phone";
import Link from "next/link";
import { AnalyticEventNames } from "../utilities/env";
import { analytics } from "../utilities/segment";

interface Props {
	showIcon?: boolean;
	className?: string;
}

export default function Phone({ showIcon, className }: Props) {
	return (
		<Link
			href="tel:18004817814"
			onClick={(e) =>
				analytics?.track(AnalyticEventNames.phoneNumberLinkClicked, {
					link: e.currentTarget.href,
				})
			}
			className={className}
		>
			{showIcon ? (
				<div className="mx-auto mb-2 w-14 rounded-lg bg-primary-50 p-4 text-primary-500">
					<SvgPhone />
				</div>
			) : null}
			1-800-481-7814
		</Link>
	);
}
