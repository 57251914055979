import generateHygraphImagePath from "@clayton-homes/home-designer/util/generate-hygraph-image-path";

export default function imageLoader({
	src,
	width,
	quality,
}: {
	src: string;
	width: number;
	quality: number;
}) {
	if (src.includes("media.graphassets.com")) {
		const [handle, ..._] = src.split("/").reverse();
		return generateHygraphImagePath({ src: handle, width, quality });
	}
	if (src.includes("api.claytonhomes.com")) {
		return `${src}?fm=webp&width=${width}`;
	}
	if (src.startsWith("http")) return src;
	return `/api/image-loader?url=${encodeURIComponent(src)}&w=${width}&q=${quality || 75}`;
}
