"use client";

import Image from "next/image";
import Link from "next/link";
import { useSearchParams } from "next/navigation";
import { Button } from "./client.button";
import Phone from "./phone";
import CirclesOnDarkBlue from "/assets/circles-on-dark-blue.webp";
import Logo from "/assets/svgs/logo.svg";

export default function Footer() {
	const searchParams = useSearchParams();
	const zipCode = searchParams?.get("zipCode") || "";
	return (
		<footer className="text-caption bg-primary-500 text-white">
			<div className="wrapper-lg relative flex items-center justify-center overflow-hidden py-28">
				<Image
					src={CirclesOnDarkBlue}
					alt=""
					aria-hidden
					quality={100}
					className="absolute min-w-[1178px]"
				/>
				<div className="relative z-10 flex flex-col flex-wrap items-center justify-center gap-x-16 gap-y-3">
					<Link
						href={{
							pathname: "/",
							query: { zipCode: zipCode },
						}}
						aria-label="Go to Design Center home page."
					>
						<Logo className="inline-block h-[38px] w-[133px] text-white" />
					</Link>
					<div className="text-intro flex gap-4">
						<Link
							href={{
								pathname: "/how-it-works",
								query: { zipCode: zipCode },
							}}
							className="border-b border-b-primary-50 hover:border-b-primary-200"
						>
							How It Works
						</Link>
						<span className="my-3 h-1 w-1 rounded-full bg-white" />
						<Link
							href={{
								pathname: "/homes",
								query: { zipCode: zipCode },
							}}
							className="border-b border-b-primary-50 hover:border-b-primary-200"
						>
							Homes
						</Link>
						<span className="my-3 h-1 w-1 rounded-full bg-white" />
						<Link
							href={{
								pathname: "/contact-us",
								query: { zipCode: zipCode },
							}}
							className="border-b border-b-primary-50 hover:border-b-primary-200"
						>
							Contact Us
						</Link>
					</div>
					<div className="text-headline-6 flex flex-col gap-1 text-center">
						<Phone className="hover:underline" />
					</div>
				</div>
			</div>
			<div className="bg-primary-600">
				<div className="wrapper-lg text-label flex flex-wrap justify-center gap-x-5 gap-y-1 py-4 uppercase">
					<Link
						href="https://www.claytonhomes.com/legal"
						target="_blank"
						className="hover:underline"
					>
						Legal
					</Link>
					<Link
						href="https://www.claytonhomes.com/privacy"
						target="_blank"
						className="hover:underline"
					>
						Privacy
					</Link>
					<Button
						buttonStyle="none"
						buttonSize="none"
						onClick={() =>
							window.Osano.cm.showDrawer("osano-cm-dom-info-dialog-open")
						}
						className="hover:underline"
					>
						Do Not Sell or Share My Personal Information
					</Button>
					Copyright © Clayton Homes {new Date().getFullYear()}
				</div>
			</div>
		</footer>
	);
}
